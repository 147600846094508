import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    background: transparent;
    padding-left: 176px;
    padding-right: 176px;
    width: calc(100% - 352px);
    /* padding-top: 196px; */
    padding-bottom: ${props => (props.desktopImg ? '215px' : 0)};
    padding-top: ${props => (props.desktopImg ? '5px' : 0)};
    background: url(${props => props.desktopImg}) no-repeat bottom;
    background-size: 100%;
    flex-direction: ${props =>
        props.direction === 'reverse' ? 'row-reverse' : 'row'};
    @media screen and (max-width: 1024px) {
        padding-left: 64px;
        padding-right: 64px;
        width: calc(100% - 128px);
    }
    @media screen and (max-width: 767px) {
        background: url(${props => props.mobileImg}) no-repeat bottom;
        background-size: 100%;
        /* padding-top: 90px; */
        /* padding-bottom: 160px; */
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;

const Content = styled.div`
    width: 50%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: white;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: left;
    }
`;
const Desc = styled.div`
    margin-top: ${props => (props.type === 1 ? '36px' : '90px')};
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    user-select: none;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
        font-size: 17px;
        font-family: 'Neuzeit Grotesk';
        text-align: left;
        letter-spacing: normal;
        line-height: 24px;
        margin-top: 23px;
    }
`;
const FeaturesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    @media screen and (max-width: 767px) {
        margin-left: 0;
        width: 100%;
        margin-top: 18px;
    }
`;
const Feature = styled.div`
    display: flex;
    margin-top: 16px;
    user-select: none;
`;
const FeatureImg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const FeatureContent = styled.div`
    font-family: 'Neuzeit Grotesk';
    color: rgba(255, 255, 255, 0.9);
    text-align: left;
    letter-spacing: normal;
    font-size: 17px;
    line-height: 24px;
    margin-left: ${props => (props.spacing === 1 ? '20px' : '12px')};

    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
        white-space: pre-wrap;
    }
`;
const FeatureDivider = styled.div`
    margin-left: ${props => (props.spacing === 1 ? '75px' : '60px')};
    margin-top: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:last-child {
        border-bottom: none;
    }
`;
export default function FeaturesPanel({
    title,
    description,
    features,
    desktopImg,
    mobileImg,
    direction = '',
    type = 0,
    spacing = 0,
}) {
    return (
        <Container
            desktopImg={desktopImg}
            mobileImg={mobileImg}
            direction={direction}
        >
            <Content>
                <div>
                    <Heading>{title}</Heading>
                    <Desc type={type}>{description}</Desc>
                </div>
            </Content>
            <FeaturesContainer>
                {features.map(feature => {
                    return (
                        <React.Fragment key={feature.title}>
                            <Feature>
                                <FeatureImg>
                                    <img
                                        width={spacing === 1 ? '56px' : '50px'}
                                        src={feature.image}
                                        alt={feature.title}
                                        style={{ objectFit: 'contain' }}
                                    />
                                </FeatureImg>
                                <FeatureContent spacing={spacing}>
                                    <div>{feature.title}</div>
                                </FeatureContent>
                            </Feature>
                            <FeatureDivider spacing={spacing}></FeatureDivider>
                        </React.Fragment>
                    );
                })}
            </FeaturesContainer>
        </Container>
    );
}
